import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { coveragesPfResponse } from "../models/classes/pci-coverages-pf.model";
import { coveragePrimeryFoodRequest } from 'libs/data-service/def/coverage-pf-request.d';
import { coveragePrimeryNonFoodRequest } from 'libs/data-service/def/coverage-pnf-request.d';
import { coveragesPnfResponse } from "../models/classes/pci-coverages-pnf.model";
import { TreatyPfListRequest } from "libs/data-service/def/treaty-pf.d"
import { TreatyPfListResponse, TreatyPfResponse } from "../models/classes/treaty-pf-data.model";
@Injectable()
export class PciDecodeJWTTokenService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }
  getDecodedJWTToken() { 
    return this.DecodeJWTToken();
  }
}
