import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { ApprovalPrimaryFoodResponse, ApprovalExcessFoodResponse, ApprovalExcessNonFoodResponse } from "../models/classes/pci-approval.model";
@Injectable()
export class PciApprovalService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }
  getApprovalPrimary(submissionId: string, riskType: string): Observable<ApprovalPrimaryFoodResponse> {
    let baseURL = this.serviceBaseUrl;
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let url = `${baseURL}/api/v1/approval/primary/food/${submissionId}`;
    if (riskType === 'Non Food') {
      url = `${baseURL}/api/v1/approval/primary/nonfood/${submissionId}`;
    }
    return this.get<ApprovalPrimaryFoodResponse>(url, requestOptions).pipe(
      map(data => this.mapToApprovalPrimaryFood(data)),
      catchError(this.handleError)
    );
  }
  public mapToApprovalPrimaryFood(data: any) {
    let covData = new ApprovalPrimaryFoodResponse(data);
    return covData
  }
  getApprovalExcessFood(submissionId: string): Observable<ApprovalExcessFoodResponse> {
    let baseURL = this.serviceBaseUrl;
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let url = `${baseURL}/api/v1/approval/excess/food/${submissionId}`;
    return this.get<ApprovalExcessFoodResponse>(url, requestOptions).pipe(
      map(data => this.mapToApprovalExcessFood(data)),
      catchError(this.handleError)
    );
  }
  public mapToApprovalExcessFood(data: any) {
    let covData = new ApprovalExcessFoodResponse(data);
    return covData
  }
  getApprovalExcessNonFood(submissionId: string): Observable<ApprovalExcessNonFoodResponse> {
    let baseURL = this.serviceBaseUrl;
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let url = `${baseURL}/api/v1/approval/excess/nonfood/${submissionId}`;
    return this.get<ApprovalExcessNonFoodResponse>(url, requestOptions).pipe(
      map(data => this.mapToApprovalExcessNonFood(data)),
      catchError(this.handleError)
    );
  }
  public mapToApprovalExcessNonFood(data: any) {
    let covData = new ApprovalExcessNonFoodResponse(data);
    return covData
  }
  protected handleError(err) {
    return throwError(err);
  }

  approvalPrimary(payload: any): Observable<any> {
    const url = `${this.serviceBaseUrl}/api/v1/approval`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getApprovalCancellationPrimary(submissionId: string, riskType: string): Observable<ApprovalPrimaryFoodResponse> {
    let baseURL = this.serviceBaseUrl;
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let url = `${baseURL}/api/v1/cancellation/approval/primary/food/${submissionId}`;
    if (riskType === 'Non Food') {
      url = `${baseURL}/api/v1/cancellation/approval/primary/nonfood/${submissionId}`;
    }
    return this.get<any>(url, requestOptions).pipe(
      map(data => this.mapToApprovalPrimaryFood(data)),
      catchError(this.handleError)
    );
  }

  approveAndIssuePostApprovalCancel(payload: any): Observable<any> {
    const url = `${this.serviceBaseUrl}/api/v1/cancellation/issue`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getPostApprovalCancellationPrimary(submissionId: string, riskType: string): Observable<ApprovalPrimaryFoodResponse> {
    let baseURL = this.serviceBaseUrl;
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let url = `${baseURL}/api/v1/cancellation/postapproval/primary/food/${submissionId}`;
    if (riskType === 'Non Food') {
      url = `${baseURL}/api/v1/cancellation/postapproval/primary/nonfood/${submissionId}`;
    }
    return this.get<any>(url, requestOptions).pipe(
      map(data => this.mapToApprovalPrimaryFood(data)),
      catchError(this.handleError)
    );
  }
}